import React, { Component } from 'react'
import Loading from 'components/Loading'


const Loader = (importMethod, waitForComponent) => {
  class LoaderComponent extends Component {
    state = {
      AsyncComponent: null,
      ready: !waitForComponent,
    }
    async componentDidMount() {
      const { default: AsyncComponent } = await importMethod()

      this.setState({
        AsyncComponent
      });
    }
    ready = () => {
      this.setState({
        ready: true,
      })
    }
    render() {
      const { AsyncComponent, ready } = this.state

      if (!AsyncComponent || !ready) {
        return <Loading className="blue" />
      }

      return (
        <AsyncComponent {...this.props} ready={this.ready} />
      )
    }
  }

  return LoaderComponent
}
export default Loader
