import React from 'react' //eslint-disable-line
import { compose, withProps } from 'recompose'
import { some, getOr } from 'lodash/fp'

import { withUser } from 'modules/database/user'
import { withClub } from 'modules/database/club'

const HasRoles = ({ children, visible }) => (
  visible ? children : null
)
export default compose(
  withUser,
  withClub,
  withProps(({ clubId, user, roles, replaceClubId }) => {
    const userRoles = getOr([], `roles.${replaceClubId || clubId}.roles`, user)
    return {
      visible: some(r => userRoles.indexOf(r) > -1, roles)
    }
  })
)(HasRoles)