// @flow
import React, {
  Fragment
} from 'react'
import {
  compose,
  withState,
  withHandlers,
  lifecycle
} from 'recompose'
import {
  app
} from 'modules/firebase'
import {
  Alert
} from 'reactstrap'
import {
  toastr
} from 'react-redux-toastr'
import Container from './Container'
import {
  MiniLoader
} from 'components/Loading'

const Verify = ({
  loading,
  error,
  success,
  setError
}) => {
  return ( <
    Container title = "Verify your account" >
    <
    div className = "text-center" > {
      loading && ( <
        Fragment >
        <
        p > Please wait... < /p> <
        MiniLoader / >
        <
        /Fragment>
      )
    } {
      error && ( <
        Alert isOpen color = "danger"
        toggle = {
          () => setError(null)
        }
        role = "alert" >
        <
        strong > Uh oh! < /strong> {error} <
        /Alert>
      )
    } <
    /div> <
    /Container>
  )
}

export default compose(
  withState('loading', 'setLoading', true),
  withState('error', 'setError', false),
  withState('success', 'setSuccess', false),
  withHandlers({
    verify: ({
      history,
      setSuccess,
      setLoading,
      setError,
      match: {
        params: {
          verificationCode
        }
      }
    }) => async () => {
      const verifyMethod = app.functions(process.env.OC_FUNCTION_REGION).httpsCallable('user-account-verifyEmail')
      try {
        if (!verificationCode) throw new Error('It looks like the verification code is invalid. Maybe try re-sending the verification email?')
        const response = await verifyMethod({
          verificationCode
        })
        if (!response.data || !response.data.success || response.data.error) {
          throw new Error(response.data ? response.data.message : 'An unknown error has occurred.')
        }
        toastr.success('Email Verified!', 'Your email address has been verified. Thank you.')
        setLoading(false)
        setSuccess(true)
        history.push('/')
      } catch (err) {
        setLoading(false)
        setError(err.message)
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      this.props.verify()
    }
  })
)(Verify)