import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reduxFirestore } from 'redux-firestore';
import { reactReduxFirebase } from 'react-redux-firebase';

import makeRootReducer from './rootReducer';

import { listenToAuthStateChanges } from 'actions/auth';
import { app } from 'modules/firebase';

export default (initialState = {}) => {
  const middleware = [thunk];
  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devTools === 'function') enhancers.push(devTools());
  }

  const reduxFirebaseConfig = {};

  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware),
      reactReduxFirebase(app, reduxFirebaseConfig),
      reduxFirestore(app, { logListenerError: true }),
      ...enhancers,
    ),
  );
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const reducers = require('./rootReducer').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  store.dispatch(listenToAuthStateChanges());

  return store;
};
