import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import cx from 'classnames';

import Logo from 'components/Logo';
import ClubSelect from 'components/ClubSelect';
import ClubMenu from 'components/ClubMenu';
import ScrollArea from './scrollbar';
import './MainNav.scss';

const NavHead = props => (
  <header className="nav-head">
    <NavLink to="/" className="nav-logo">
      <Logo />
    </NavLink>
  </header>
);

const NavList = () => (
  <ScrollArea
    className="nav-list-container"
    horizontal={false}
    verticalScrollbarStyle={{ width: '4px', marginLeft: '10px' }}
  >
    <ul className="list-unstyled nav-list clearfix">
      <Route path="/:clubSlug" render={p => <ClubMenu {...p} />} />
      <Route path="*" render={p => <ClubSelect {...p} />} />
    </ul>
  </ScrollArea>
);

export default props => (
  <nav className={cx('site-nav', { mini: props.mini })}>
    <NavHead {...props} />
    <NavList />
  </nav>
);
