import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Logo from './Logo';

const Footer = () => (
  <footer>
    <Container fluid>
      <Row>
        <Col xs={6} className="footer-left">
          <div className="footer-logo">
            <Logo color="#636363" />
          </div>
        </Col>
        <Col xs={6} className="footer-right">
          {new Date().getFullYear()} © OpenClub
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
