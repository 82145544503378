// @flow

import React, { type Node } from 'react'
import cx from 'classnames'
import './AuthView.scss'

type PropsType = {
  children: Node,
  className?: string
}

const AuthView = ({ children, className }: PropsType) => (
  <div className="view">
      <div className={cx("view-content view-pages view-session d-flex justify-content-center align-items-center flex-column", className)}>
        {children}
      </div>
  </div>

)
export default AuthView
