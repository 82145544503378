import moment from 'moment';

export const required = value =>
  value !== undefined && !value && 'This field is required.';

export const email = value =>
  !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
    value,
  ) && 'This field must contain an email.';

export const maxLength = max => value =>
  value &&
  value.length > max &&
  `This field must contain less than ${max} characters.`;

export const minLength = min => value =>
  value &&
  value.length < min &&
  `This field must contain at least ${min} characters.`;

export const postcode = value =>
  !/^[\d]{3,4}/.test(value) && `This field must contain a valid postcode.`;

export const matchPassword = password => value =>
  required(value) || (value !== password && 'This password does not match.');

export const slug = value =>
  !/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value) &&
  'Your club address must contain only lowercase characters, numbers and hyphens (-).';

export const oneRole = (value = []) =>
  value.length === 0 && 'You must select at least one permission.';

export const number = value =>
  !!value &&
  !/\d+(?:,\d{1,2})?/.test(value) &&
  'Please enter a valid number with a maximum of 2 decimal places (ie. 29.89).';

export const dateformat = value => {
  if (!value) return null;
  try {
    const m = moment().format(value);
    if (moment(m, value).isValid()) {
      return null;
    }
    throw new Error();
  } catch (e) {
    return 'Please enter a valid date/time format';
  }
};
