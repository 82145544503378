import React, { Component, Fragment } from 'react';
import {
  Modal,
  Card,
  CardBody,
  CardHeader,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import get from 'lodash/fp/get';
import { reduxForm, Field, Form } from 'redux-form';

import { Input } from 'components/Fields';
import userEnhancer from 'modules/database/user';
import * as v from 'utils/fieldValidators';

import Wallet, { NewCard } from 'components/Wallet';

import {
  MdChevronLeft as ChevronLeft,
  MdChevronRight as ChevronRight,
  MdAccountCircle as AccountIcon,
  MdLocationOn as LocationIcon,
  MdAccountBalanceWallet as WalletIcon,
  MdVerifiedUser as PrivacyIcon,
  MdAssignment as TermsIcon,
  MdCheckCircle as DoneIcon,
} from 'react-icons/md';

import './UserOnboarding.scss';

const steps = [
  [
    'Personal Details',
    AccountIcon,
    "This information helps OpenClub and your clubs identify you. Let's start with your name and date of birth.",
  ],
  [
    'Address',
    LocationIcon,
    'Your clubs can use this address to send you mail and merchandise. We also print this on invoices.',
  ],
  [
    'Wallet',
    WalletIcon,
    'We store your payment details on behalf of your club. You can use these for auto renewals and event registrations.',
    true,
  ],
  // ['Interests', InterestsIcon, 'Let us know your interests to help us better understand you.', true],
  ['Privacy', PrivacyIcon, 'A little about how we handle your data.'],
  ['Terms of Service', TermsIcon, 'Terms and conditions for using OpenClub.'],
  [
    'Thank you!',
    DoneIcon,
    "Congratulations! You're now an OpenClub member! Welcome to the club!",
  ],
];

const questions = [
  () => (
    <Fragment>
      <FormGroup className="row">
        <div className="col-6">
          <Label>First Name</Label>
          <Field
            component={Input}
            name="name.first"
            required
            type="text"
            validate={[v.required, v.maxLength(50)]}
          />
        </div>
        <div className="col-6">
          <Label>Last Name</Label>
          <Field
            component={Input}
            name="name.last"
            required
            type="text"
            validate={[v.required]}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Date of Birth</Label>
        <Field
          component={Input}
          name="birthdate"
          required
          type="date"
          validate={[v.required]}
        />
      </FormGroup>
    </Fragment>
  ),
  () => (
    <Fragment>
      <FormGroup>
        <Label>Street Address</Label>
        <Field
          component={Input}
          name="address.line1"
          required
          type="text"
          placeholder="Address Line 1"
          validate={[v.required]}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={Input}
          name="address.line2"
          placeholder="Address Line 2"
          type="text"
        />
      </FormGroup>
      {window.location.hostname.includes('.uk') ? (
        <Fragment>
          <FormGroup className="row">
            <div className="col-6">
              <Label>Town</Label>
              <Field
                component={Input}
                name="address.town"
                required
                type="text"
                validate={[v.required]}
              />
            </div>
            <div className="col-6">
              <FormGroup>
                <Label>County</Label>
                <Field
                  component={Input}
                  name="address.county"
                  required
                  type="text"
                  validate={[v.required]}
                />
              </FormGroup>
            </div>
            <Label>Postcode</Label>
            <Field
              component={Input}
              name="address.postcode"
              required
              type="number"
              validate={[v.required, v.postcode]}
            />
          </FormGroup>
        </Fragment>
      ) : (
        <Fragment>
          <FormGroup className="row">
            <div className="col-6">
              <Label>Suburb</Label>
              <Field
                component={Input}
                name="address.suburb"
                required
                type="text"
                validate={[v.required]}
              />
            </div>
            <div className="col-6">
              <Label>Postcode</Label>
              <Field
                component={Input}
                name="address.postcode"
                required
                type="number"
                validate={[v.required, v.postcode]}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>State</Label>
            <Field
              component={Input}
              name="address.state"
              required
              type="text"
              validate={[v.required]}
            />
          </FormGroup>
        </Fragment>
      )}
    </Fragment>
  ),
  ({ userSettings }) =>
    get('payments.sources')(userSettings) ? (
      <div className="text-center">
        <h4>Card Added</h4>
        <p>
          Your card has been sucessfully added. You can add additional cards
          later from the My Account menu.
        </p>
        <Wallet />
      </div>
    ) : (
      <div className="text-center">
        <h4>Add your first card</h4>
        <p>
          You can add your first card now or add it later from the My Account
          menu.
        </p>
        <NewCard inline />
      </div>
    ),
  // () => (
  //     <Fragment>
  //       Interests thingo
  //     </Fragment>
  // ),
  () => (
    <Fragment>
      <p>
        We take your privacy seriously. That's why we believe in your right to
        choose how your data is used.
      </p>
      <p>
        Your personal details are held by us and only shared to the clubs you
        join. By default we do not track user engagement on our platform. If
        you'd like to be involved and make our platform better you can enrol
        below.
      </p>
      <p>
        To understand how we approach privacy, we recommend reading our privacy
        policy.
      </p>
      <p>
        <a
          href="https://www.openclub.co/privacy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Read our Privacy Policy.
        </a>
      </p>
      <Label>
        <Field component={Input} name="optInToTracking" type="checkbox" />
        I'd like to send anonymous usage data to OpenClub engineers.
      </Label>
    </Fragment>
  ),
  () => (
    <Fragment>
      <p>
        To maintain the high quality of service expected by our users we
        maintain a strict terms of service that govern the use of our platform.
        Inappropriate content, fraud and illegal activities will not be
        tolerated on our platform.
      </p>
      <p>
        We recommend reading the terms of service before using our platform.
      </p>
      <p>
        <a
          href="https://www.openclub.co/legal/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read our Terms of Service.
        </a>
      </p>
      <FormGroup>
        <Label>
          <Field
            component={Input}
            name="legal.tos_acceptance"
            required
            type="checkbox"
            validate={[v.required]}
          />
          I agree to the{' '}
          <a
            href="https://www.openclub.co/legal/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          .
        </Label>
      </FormGroup>
      <FormGroup>
        <Label>
          <Field component={Input} name="legal.mail_consent" type="checkbox" />I
          consent to receiving useful emails on how to use OpenClub and
          occasional updates from the OpenClub team.
        </Label>
      </FormGroup>
    </Fragment>
  ),
  () => (
    <Fragment>
      <p>It was that easy! You're now setup to use OpenClub!</p>
    </Fragment>
  ),
];

const QuestionForm = reduxForm({
  form: 'userOnboarding',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  ({
    handleSubmit,
    step,
    skippable,
    prevStep,
    nextStep,
    submitting,
    user,
    userSettings,
  }) => {
    const Question = questions[step];

    return (
      <Form onSubmit={handleSubmit}>
        <div className="onboarding-body">
          <div className="onboarding-form" onSubmit={handleSubmit}>
            <Question user={user} userSettings={userSettings} />
          </div>
        </div>
        <div className="onboarding-steps">
          <div className="onboarding-steps-buttons">
            <Button
              onClick={prevStep}
              className="btn-fab"
              color="primary"
              size="lg"
              outline
              disabled={step === 0}
            >
              <ChevronLeft size="2rem" style={{ width: '2rem' }} />
            </Button>
            {skippable && (
              <Button onClick={nextStep} color="primary" outline>
                Skip
              </Button>
            )}
            <Button
              type="submit"
              className="btn-fab"
              color={step === steps.length - 1 ? 'success' : 'primary'}
              size="lg"
              outline
              disabled={submitting}
            >
              {step === steps.length - 1 ? (
                <DoneIcon size="2rem" style={{ width: '2rem' }} />
              ) : (
                <ChevronRight size="2rem" style={{ width: '2rem' }} />
              )}
            </Button>
          </div>
        </div>
      </Form>
    );
  },
);

class UserOnboarding extends Component {
  state = {
    step: 0,
  };
  setStep = step => this.setState({ step });
  nextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };
  submit = values => {
    if (this.state.step === steps.length - 1) {
      return this.props.setUser({ onboardingComplete: true });
    }
    this.nextStep();

    return this.props.setUser(values);
  };
  prevStep = () => {
    this.setState({ step: this.state.step - 1 });
  };
  buildAuthUser = (auth, user) => {
    const newUser = {};

    if (auth.user.displayName && !user.name) {
      const [first, ...last] = auth.user.displayName.split(' ');
      newUser.name = {
        first,
        last: last.join(' '),
      };
    }

    return {
      ...user,
      ...newUser,
    };
  };
  render() {
    const {
      state: { step },
      props: { auth, user, loading, userSettings },
    } = this;
    const [title, Icon, description, skippable] = steps[step];

    if (!auth.authenticated) return;

    const authUser = this.buildAuthUser(auth, user);

    const initialValues = (() => {
      const onboardingInitialValues = localStorage.getItem(
        'onboardingInitialValues',
      );
      if (onboardingInitialValues) {
        try {
          const values = JSON.parse(onboardingInitialValues);
          localStorage.removeItem('onboardingInitialValues');
          if (values.address) {
            values.address.country = window.location.hostname.includes('.co.uk')
              ? 'GB'
              : 'AU';
          } else {
            values.address = {
              country: window.location.hostname.includes('.co.uk')
                ? 'GB'
                : 'AU',
            };
          }
          return values;
        } catch (error) {
          localStorage.removeItem('onboardingInitialValues');
        }
      }
      return {
        address: {
          country: window.location.hostname.includes('.co.uk') ? 'GB' : 'AU',
        },
      };
    })();

    return (
      <Modal
        isOpen={!user.onboardingComplete}
        contentClassName="user-onboarding"
      >
        <Card className="mb-3 vert form-card">
          <CardHeader className="onboarding-header">
            <span>
              <Icon size="4rem" style={{ width: '4rem' }} />
            </span>
            <h3>{title}</h3>
            <p>{description}</p>
          </CardHeader>
          <CardBody>
            {loading ? (
              <p>We're just getting things ready. One moment...</p>
            ) : (
              <QuestionForm
                step={step}
                onSubmit={this.submit}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                skippable={skippable}
                user={user}
                userSettings={userSettings}
                initialValues={{
                  ...authUser,
                  ...initialValues,
                }}
              />
            )}
          </CardBody>
        </Card>
      </Modal>
    );
  }
}

const UserOnboardingData = userEnhancer(UserOnboarding);
export default UserOnboardingData;
