import {
  STATE_CHANGED,
  AUTH_ERROR,
} from 'actions/auth'

const initialState = {
  authenticated: false,
  error: null,
  user: null,
}

const ACTION_HANDLERS = {
  [STATE_CHANGED]: (state, { user }) => ({
    ...state,
    error: null,
    user,
    authenticated: !!user,
  }),
  [AUTH_ERROR]: (state, { code, message }) => ({
    ...state,
    error: code ? { code, message } : null
  })
}

export default function authReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
