import React from 'react'
import cx from 'classnames'

export const Grid = ({ className }) => (
  <div className={cx('sk-cube-grid', className)}>
    <div className="sk-cube sk-cube1" />
    <div className="sk-cube sk-cube2" />
    <div className="sk-cube sk-cube3" />
    <div className="sk-cube sk-cube4" />
    <div className="sk-cube sk-cube5" />
    <div className="sk-cube sk-cube6" />
    <div className="sk-cube sk-cube7" />
    <div className="sk-cube sk-cube8" />
    <div className="sk-cube sk-cube9" />
  </div>
)

export const MiniLoader = () => (
  <div className="mini-loader">
    <Grid className="blue"/>
  </div>
)

const Loading = ({ className }) => (
  <div className="loader">
    <div className="loader--root" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, background: '#d7d7d7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid className={className} />
    </div>
  </div>
)
export default Loading