import React from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reduxForm, Form, Field } from 'redux-form';
import { compose, withHandlers, withState } from 'recompose';
import Helmet from 'react-helmet';
import { Input } from 'components/Fields';
import { get } from 'lodash/fp';

import * as v from 'utils/fieldValidators';

import rowers from 'images/rowers.jpg';

import {
  MdCreditCard as CreditCardIcon,
  MdContacts as Contacts,
  MdNotificationsActive as Notifications,
  MdInsertChart as Reporting,
  MdFormatListNumbered as Finances,
  MdStorage as CustomFields,
} from 'react-icons/md';

import {
  IoIosPeople as MembershipsIcon,
  IoIosCalendar as EventsIcon,
  IoIosText as EngagementIcon,
} from 'react-icons/io';

import './Home.scss';

function doScrolling(elementY, duration) {
  var startingY = window.pageYOffset;
  var diff = elementY - startingY;
  var start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

const scrollTo = ele => {
  doScrolling(ele.getBoundingClientRect().y, 250);
};

let refs = {};

const Home = ({ submitting, handleSubmit }) => (
  <div className="home-container">
    <Helmet>
      <title>OpenClub — membership management made easy</title>
      <meta property="og:site_name" content="OpenClub" />
      <meta property="og:title" content="Home" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="http://static1.squarespace.com/static/587c4738b8a79b66d30ba9be/t/5b12d226f950b72b53454976/1527960104759/OC-Icon-Solid.png?format=1000w"
      />
      <meta property="og:image:width" content="1000" />
      <meta property="og:image:height" content="1000" />
      <meta
        name="description"
        content="Be part of your community without the stress of managing it. We give club organisers the tools to manage memberships, events and engagement. Try OpenClub today — it's free."
      />
    </Helmet>
    <section className="home" ref={r => (refs.home = r)}>
      <div
        className="background"
        style={{ backgroundColor: 'black', backgroundImage: `url(${rowers})` }}
      />
      <div className="blur" />
      <div className="home-hero-text">
        <h1>
          Be part of your community
          <br className="hidden-sm-down" /> without the stress of managing it.
        </h1>
        <h3>
          We give club organisers the tools to manage memberships, events and
          engagement simply and automatically.
        </h3>
        <Button size="lg" color="light" onClick={() => scrollTo(refs.feature1)}>
          Learn More
        </Button>
      </div>
    </section>
    <section className="feature" ref={r => (refs.feature1 = r)}>
      <div className="row p-0 m-0 align-items-stretch w-100">
        <div className="col-12 col-md-3 m-0 p-0">
          <div
            className="background"
            style={{
              backgroundColor: 'black',
              backgroundImage: `url(${rowers})`,
            }}
          />
          <div className="blur" />
        </div>
        <div className="col-12 col-md-9 p-5 align-items-center">
          <h1 className="mt-4">Simplify club management.</h1>
          <h3>Manage less. Stress less.</h3>
          <h5>
            Packed with features, OpenClub lets your committee stress less with
            tools that let them achieve more.
          </h5>
          <div className="clearfix" />
          <div className="row py-5">
            <div className="col-md-4">
              <div className="pt-2 text-center">
                <MembershipsIcon size="40" />
                <h5>Memberships</h5>
              </div>
              <hr />
              <p>Online registration, automated renewals & online payments.</p>
            </div>
            <div className="col-md-4">
              <div className="pt-2 text-center">
                <EventsIcon size="40" />
                <h5>Events</h5>
              </div>
              <hr />
              <p>
                Calendaring, members-only tickets, reminders & online payments.
              </p>
            </div>
            <div className="col-md-4">
              <div className="pt-2 text-center">
                <EngagementIcon size="40" />
                <h5>Engagement</h5>
              </div>
              <hr />
              <p>Growth analytics, social media integration & announcements.</p>
            </div>
          </div>
          <div className="py-4" />
          <h4 className="mt-4">Packed full of extras</h4>
          <table className="table">
            <tbody>
              <tr>
                <td width="28px">
                  <CreditCardIcon size="28" />
                </td>
                <td>
                  <h6>Payment Processing</h6>
                  <p>
                    Accept MasterCard, Visa and American Express out of the box.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <Contacts size="28" />
                </td>
                <td>
                  <h6>Contacts</h6>
                  <p>
                    Share contacts and a members directory with your community.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <CustomFields size="28" />
                </td>
                <td>
                  <h6>Custom Fields</h6>
                  <p>Extend our capability with your own question sets.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <Notifications size="28" />
                </td>
                <td>
                  <h6>Notifications</h6>
                  <p>
                    Stay informed of new activity in-app, by email or by SMS.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <Finances size="28" />
                </td>
                <td>
                  <h6>Finances</h6>
                  <p>
                    Track your payments and export them to your accounting
                    suite.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <Reporting size="28" />
                </td>
                <td>
                  <h6>Reporting & Analytics</h6>
                  <p>
                    Cutomise your own realtime reporting & analytics dashboard.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section className="feature" ref={r => (refs.feature1 = r)}>
      <div className="row p-0 m-0 align-items-stretch w-100">
        <div className="col-12 col-md-9 p-5 align-items-center">
          <h1 className="mt-4">Want to know more?</h1>
          <h3>Let's talk. We're friendly.</h3>
          <h5>
            We're club members just like you. We know the pains of managing a
            community while trying to enjoy it. We're here to help you focus on
            what's important — not renewals, finances and scheduling.
          </h5>
          <div className="pb-5 pt-2" />
          <h3>Made with ❤ in Melbourne</h3>
          <p>
            We're located all over Australia but the heart of our product is in
            Melbourne. Come visit us some time.
          </p>
          <p>
            Level 19, 114 William Street
            <br />
            Melbourne VIC 3000 — 
            <a
              href="https://goo.gl/maps/XRfp516pxPo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get directions
            </a>
          </p>
          <Form className="py-5" onSubmit={handleSubmit}>
            <FormGroup className="row">
              <div className="col-6">
                <Label>First Name</Label>
                <Field
                  component={Input}
                  name="firstname"
                  required
                  validate={[v.required]}
                />
              </div>
              <div className="col-6">
                <Label>Last Name</Label>
                <Field
                  component={Input}
                  name="lastname"
                  required
                  validate={[v.required]}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Organisation Name</Label>
              <Field
                placeholder="Let us know who you represent"
                component={Input}
                name="organisationName"
                type="email"
                required
                validate={[v.required, v.email]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email Address</Label>
              <Field
                placeholder="Where can we email you?"
                component={Input}
                name="email"
                type="email"
                required
                validate={[v.required, v.email]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Message</Label>
              <Field
                placeholder="What would you like to know? When is a good time for a demo? What is your coffee preference?"
                component={Input}
                name="message"
                type="textarea"
              />
            </FormGroup>
            <FormGroup>
              <Button
                size="lg"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                Let's Talk
              </Button>
            </FormGroup>
          </Form>
        </div>
        <div className="col-12 col-md-3 m-0 p-0">
          <div
            className="background"
            style={{
              backgroundColor: 'black',
              backgroundImage: `url(${rowers})`,
            }}
          />
          <div className="blur" />
        </div>
      </div>
    </section>
    <footer>
      <div className="row">
        <div className="col-12 text-center py-2">
          Copyright © OpenClub Pty Ltd. All Rights Reserved.
          <br />
          <small>
            ABN 84 615 034 752 |{' '}
            <Link to="/legal/terms-of-service">Terms of Service</Link> |{' '}
            <Link to="/legal/privacy-policy">Privacy Policy</Link>
          </small>
        </div>
      </div>
    </footer>
  </div>
);

export default compose(
  withState('submitted', 'setSubmitted', false),
  withHandlers({
    stuff: () => () => {},
  }),
  reduxForm({
    form: 'contactForm',
  }),
  connect(({ form }) => {
    const message = get('contactForm.values.message')(form);
    if (message) {
      window.Intercom('showNewMessage', message);
    }
    return {};
  }),
)(Home);
