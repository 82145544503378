import React from 'react';
import cx from 'classnames';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { compose, withState, withPropsOnChange, withHandlers } from 'recompose';

import './Wysiwyg.scss';

const toolbar = {
  options: ['inline', 'list', 'link', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  emoji: {},
  link: {},
};

const enhancers = compose(
  withState('editorState', 'setEditorState', EditorState.createEmpty()),
  withPropsOnChange(
    ['input'],
    ({ input: { value }, meta: { dirty }, setEditorState }) => {
      if (dirty) return;
      if (!value) return;

      try {
        const contentState = convertFromRaw(JSON.parse(value));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      } catch (error) {
        console.error('Error while parsing default value', error);
      }
    },
  ),
  withHandlers({
    onEditorStateChange: ({
      input: { onChange },
      setEditorState,
    }) => editorState => {
      setEditorState(editorState);
      onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    },
  }),
);

const Wysiwyg = ({
  editorState,
  onEditorStateChange,
  meta: { touched, error },
  className,
  style,
}) => (
  <div
    style={style}
    className={cx(
      'form-control',
      {
        'is-invalid': touched && error,
        'is-valid': touched && !error,
      },
      className,
    )}
  >
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbarStyle={{ fontSize: '12px' }}
      toolbar={toolbar}
    />
  </div>
);

export default enhancers(Wysiwyg);
