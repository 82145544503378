// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, Field, Form } from 'redux-form'
import get from 'lodash/fp/get'

import { Input } from 'components/Fields'
import { required, email, minLength } from 'utils/fieldValidators'
import {
    Label,
    FormGroup,
    Button,
} from 'reactstrap'

import { signUp } from 'actions/auth'

import Container from './Container'

const minLength8 = minLength(8)

const SignUp = ({ handleSubmit, submitting, password }) => {
  return (
    <Container title="Sign up to OpenClub" showSocialSignIn>
      <Form onSubmit={handleSubmit} disabled={submitting}>
        <p>
          You can sign up for OpenClub by providing your own login details or using an identity provider like Google or Facebook.
        </p>
        <hr />
        <FormGroup className="mb-4">
          <Label>Email Address</Label>
          <Field
            component={Input}
            name="email"
            placeholder="eg. steve@openclub.co"
            type="email"
            validate={[required, email]}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Label>Password</Label>
          <Field
            component={Input}
            name="password"
            placeholder="verysecretpassword"
            type="password"
            validate={[required, minLength8]}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Label>Confirm Password</Label>
          <Field
            component={Input}
            name="confirmPassword"
            placeholder="verysecretpassword"
            type="password"
            validate={[required, minLength8]}
          />
        </FormGroup>
        <FormGroup className="text-center">
          <Button size="lg" color="primary" type="submit" disabled={submitting}>{submitting ? 'Processing...' : 'Sign Up'}</Button>
        </FormGroup>
      </Form>
      <hr />
      <div className="text-center">
        <Link to="/auth/sign-in">Already have an account? Sign in.</Link>
      </div>
    </Container>
  )
}
const SignUpReduxForm = reduxForm({
  form: 'signUp',
  onSubmit: signUp,
  validate: values => {
    const errors = {}
    if (values.password !== values.confirmPassword) {
      const error = 'The passwords do not match.'
      errors.password = error
      errors.confirmPassword = error
    }
    return errors
  }
})(SignUp)

export default connect(({ auth, form }) => ({
  error: auth.error,
  password: get('signUp.values.password')(form),
}))(SignUpReduxForm)
