import { combineReducers } from 'redux'
import { reducer as toastr } from 'react-redux-toastr'
import { reducer as form } from 'redux-form'
import { firestoreReducer as firestore } from 'redux-firestore'
import { firebaseStateReducer as firebase } from 'react-redux-firebase'

import auth from 'reducers/auth'
import ui from 'reducers/ui'

const makeRootReducer = asyncReducers => combineReducers({
  auth,
  ui,
  toastr,
  form,
  firestore,
  firebase,
  ...asyncReducers
})

const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer

export {
  injectReducer
}
