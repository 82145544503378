import React from 'react';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';

type PropsType = {
  children: Node,
};

const Stripe = ({ children }: PropsType) => (
  <StripeProvider apiKey={process.env.OC__STRIPE_PUBLIC_KEY}>
    <Elements>{children}</Elements>
  </StripeProvider>
);

export const withStripe = WrappedComponent => {
  const InjectWrappedComponent = injectStripe(WrappedComponent);
  return props => (
    <Stripe>
      <InjectWrappedComponent {...props} />
    </Stripe>
  );
};

export default Stripe;
