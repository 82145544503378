import React from 'react'
import { Link } from 'react-router-dom'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { get, isEmpty } from 'lodash/fp'

import {
  MdFace as IconFace,
  MdSecurity as IconSecurity,
  MdHelp as IconHelp,
  MdPowerSettingsNew as IconLogout,
} from 'react-icons/md'

import userEnhancer from 'modules/database/user'

import ocThumb from 'images/ocThumb.png'

const ProfileMenu = ({ user, loading }) => (
  <div className="wrap profile">
      {!loading && !isEmpty(user) && (
        <UncontrolledDropdown>
          <DropdownToggle tag="div">
              <img src={get('photo.original', user) || ocThumb} alt="avatar"/>
          </DropdownToggle>
          <DropdownMenu right style={{minWidth: '12rem'}}>
              <DropdownItem header>Hello, {get('name.first', user)}!</DropdownItem>
              <DropdownItem divider/>
              <DropdownItem tag={Link} to="/account">
                <IconFace size="16"/> My Account
              </DropdownItem>
              <DropdownItem tag={Link} to="/account/security">
                <IconSecurity size="16"/> Security
              </DropdownItem>
              <DropdownItem tag="a" target="_blank" rel="noopener noreferrer" href="https://help.openclub.co/">
                <IconHelp size="16"/> Help
              </DropdownItem>
              <DropdownItem tag={Link} to="/auth/sign-out">
                <IconLogout size="16"/> Sign out
              </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
  </div>
)
export default userEnhancer(ProfileMenu)
