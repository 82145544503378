import React, { Fragment } from 'react'

import { Input as BsInput, FormFeedback } from 'reactstrap'

const Input = ({ input, meta, ...rest }) => (
  <Fragment>
    <BsInput {...rest} {...input} valid={meta.touched && meta.valid} invalid={meta.submitFailed && meta.invalid} />
    {meta.submitFailed && meta.error && <FormFeedback>{meta.error}</FormFeedback>}
  </Fragment>
)
export default Input
