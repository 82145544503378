import React, { Component, Fragment } from 'react';
import { FormGroup, Label, Form, Button } from 'reactstrap';
import { CardElement } from 'react-stripe-elements';
import { toastr } from 'react-redux-toastr';

import { withStripe } from 'modules/stripe';
import { app } from 'modules/firebase';

class NewCards extends Component {
  state = {
    submitting: false,
  };
  handleSubmit = async e => {
    e.preventDefault();
    const {
      props: { stripe },
    } = this;

    try {
      this.setState({
        submitting: true,
      });
      const token = await stripe.createToken();
      const paymentAddCard = app
        .functions(process.env.OC_FUNCTION_REGION)
        .httpsCallable('payments-addCard');
      const result = await paymentAddCard(token);
      this.setState({
        submitting: false,
      });
      if (!result.data || result.data.error)
        throw new Error(
          result.data ? result.data.message : 'An unknown error occurred.',
        );
      if (result.data.success) {
        toastr.success('Card Added', 'The card has been successfully added');
        this.card.clear();
      }
    } catch (err) {
      this.setState({
        submitting: false,
      });
      toastr.error('Something went wrong!', err.message);
    }
  };
  render() {
    const {
      state: { submitting },
      props: { inline },
    } = this;

    const form = (
      <Fragment>
        <FormGroup>
          <Label> Credit Card </Label>{' '}
          <CardElement
            onReady={card => {
              this.card = card;
              card.focus();
            }}
            className="form-control"
            hidePostalCode
          />
        </FormGroup>{' '}
        <FormGroup>
          <Button
            color="primary"
            type={inline ? 'button' : 'submit'}
            disabled={submitting}
            onClick={this.handleSubmit}
          >
            Add to Wallet{' '}
          </Button>{' '}
        </FormGroup>{' '}
      </Fragment>
    );

    if (inline) {
      return form;
    }
    return <Form onSubmit={this.handleSubmit}> {form} </Form>;
  }
}
export default withStripe(NewCards);
