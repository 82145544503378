import firebase from '@firebase/app';

import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/functions';
import '@firebase/messaging';
import '@firebase/storage';

const appConfig = {
  apiKey: process.env.OC_FB_API_KEY,
  authDomain: process.env.OC__AUTH_DOMAIN,
  databaseURL: process.env.OC__DB_URL,
  projectId: process.env.OC__PROJECT_ID,
  storageBucket: process.env.OC__STORAGE_BUCKET,
  messagingSenderId: process.env.OC__MESSAGING_SENDER_ID,
};

const firestoreConfig = {};

export const app = firebase.initializeApp(appConfig);
window.fapp = app;

export const storage = firebase.storage();

export const firestore = app.firestore();
firestore.settings(firestoreConfig);

// firestore.enablePersistence({ experimentalTabSynchronization:true })
//   .catch(err => {
//     if (err.code === 'failed-precondition') {
//       console.log('Multi-tab offline persistence is still in beta.')
//     }
//     if (err.code === 'unimplemented') {
//       console.log('Offline mode is not supported in this browser.')
//     }
//   })

export const auth = firebase.auth();

export const providers = {
  facebook: new firebase.auth.FacebookAuthProvider(),
  google: new firebase.auth.GoogleAuthProvider(),
};
