import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { compose, withHandlers, lifecycle, withState } from 'recompose';
import User from '@openclub/common/models/User';

import { withAuth, onAuthChange } from 'modules/enhancers';
import { withFirestore } from 'react-redux-firebase';

import { app } from 'modules/firebase';

const reget = get.convert({
  rearg: false,
});
const baseUserListener = (uid, subcollection) => ({
  collection: 'users',
  doc: uid,
  ...(subcollection
    ? {
        subcollections: [
          {
            collection: subcollection,
          },
        ],
      }
    : {}),
});

const userSettingsListener = uid => ({
  collection: 'userSettings',
  doc: uid,
});

const handlers = withHandlers({
  getUser: ({ firestore, auth, setLoading }) => async () => {
    if (!auth.authenticated) return;
    await firestore.setListeners([
      userSettingsListener(auth.user.uid),
      baseUserListener(auth.user.uid),
      baseUserListener(auth.user.uid, 'roles'),
      baseUserListener(auth.user.uid, 'memberships'),
    ]);
    setLoading(false);
  },
  handleLogout: ({ firestore }) => uid => {
    firestore.unsetListeners([
      userSettingsListener(uid),
      baseUserListener(uid),
      baseUserListener(uid, 'roles'),
      baseUserListener(uid, 'memberships'),
    ]);
  },
  setUserAndNotify: ({ firestore, auth }) => async fields => {
    try {
      if (!auth.authenticated) throw new Error('You are not logged in.');
      const update = await User.validate(fields);
      await firestore.set(`users/${auth.user.uid}`, update, {
        merge: true,
      });
      toastr.success(
        'Details Saved',
        'Your account details have been saved successfully.',
      );
    } catch (err) {
      toastr.error('Uh-oh! Something went wrong!', err.message);
    }
  },
  setUser: ({ firestore, auth }) => async fields => {
    if (!auth.authenticated) throw new Error('You are not logged in.');
    const update = await User.validate(fields);
    return firestore.set(`users/${auth.user.uid}`, update, {
      merge: true,
    });
  },
  setUserSetting: ({ firestore, auth }) => fields => {
    if (!auth.authenticated) throw new Error('You are not logged in.');
    return firestore.set(`userSettings/${auth.user.uid}`, fields, {
      merge: true,
    });
  },
  updateEmail: () => async email => {
    try {
      const updateEmailMethod = app
        .functions(process.env.OC_FUNCTION_REGION)
        .httpsCallable('user-account-changeEmail');
      const result = await updateEmailMethod({
        email,
      });
      if (!result.data || !result.data.success) {
        throw new Error(
          result.data ? result.data.message : 'An unknown error has occurred.',
        );
      }
      toastr.success(
        'Verification Email Sent',
        'Please click on the link we sent to your inbox to confirm your email change.',
      );
    } catch (err) {
      toastr.error('Uh-oh! Something went wrong!', err.message);
    }
  },
});

const defaultConnector = connect(({ auth, firestore }, { loading }) => {
  const uid = get('user.uid')(auth);

  const user = uid ? get(`data.users.${uid}`, firestore) : null;
  const userSettings = uid ? get(`data.userSettings.${uid}`, firestore) : null;

  const loadingCheck = (() => {
    if (loading) return true;
    if (!uid) return false;
    // Immediately invoked function to determine loading status — yes, complex.
    const resources = [
      `users/${uid}/memberships`,
      `users/${uid}/roles`,
      `users/${uid}`,
    ];

    const requested = map(reget(firestore.status.requested), resources);
    const requesting = map(reget(firestore.status.requesting), resources);

    const checks = [
      requested.includes(false),
      requested.includes(true) && requesting.includes(true),
    ];

    return checks.includes(true);
  })();

  return {
    loading: loadingCheck,
    auth,
    user: user || {},
    userSettings: userSettings || {},
  };
});

export const rootUserEnhancer = compose(
  withAuth,
  withFirestore,
  withState('loading', 'setLoading', true),
  handlers,
  defaultConnector,
  lifecycle({
    componentWillReceiveProps(nextProps) {
      onAuthChange(this.props, nextProps)({
        onLogin: () => {
          this.props.getUser();
          const redirectAfterAuth = localStorage.getItem('redirectAfterAuth');
          localStorage.removeItem('redirectAfterAuth');
          if (redirectAfterAuth) {
            setTimeout(() => {
              this.props.history.replace(redirectAfterAuth);
            }, 200);
          }
        },
        onLogout: ({ uid }) => {
          this.props.handleLogout(uid);
        },
      });

      if (this.props.loading === true && nextProps.loading === false) {
        const getTracking = getOr(true, 'user.optInToTracking', nextProps);

        if (getTracking) {
          window.gtag('set', {
            user_id: get('auth.user.uid', nextProps),
          });
          window.Intercom('update', {
            email: nextProps.user.email,
            name: `${get('user.name.first', nextProps)} ${get(
              'user.name.last',
              nextProps,
            )}`,
            userId: nextProps.auth.user.uid,
          });
        }

        window['ga-disable-UA-85428168-1'] = getTracking === false;
      }
    },
  }),
);

export const withUser = compose(
  withFirestore,
  defaultConnector,
  handlers,
);

export default withUser;
