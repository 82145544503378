import React, { Fragment } from 'react';
import { rootUserEnhancer } from 'modules/database/user';
import { Route, withRouter, Switch } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

import Loader from 'components/Loader';

// Auth
import AuthRoutes from './routes/Auth/Routes';
import App from './App';
import Home from './routes/Site/Home';

const MyClubs = Loader(() => import('./routes/MyClubs'));
const Account = Loader(() => import('./routes/Account'));
const Homepage = Loader(() => import('./routes/Homepage'));
const Club = Loader(() => import('./routes/Club'));
const FourOhFour = Loader(() => import('./routes/Club/404'));
const CreateClub = Loader(() => import('./routes/CreateClub'));

// Invitations Routes
const InvitationRole = Loader(() =>
  import('./routes/Club/Invitations/RoleInvitation'),
);
const InvitationMember = Loader(() =>
  import('./routes/Club/Invitations/MemberInvitation'),
);

const Router = ({ auth: { authenticated } }) => (
  <Fragment>
    <Switch>
      <Route path="/auth" component={AuthRoutes} />
      <Route
        render={props => (
          <App {...props}>
            <Switch>
              <Route path="/" exact component={Homepage} />
              <Route path="/create-club" component={CreateClub} />
              <Route path="/home" exact component={Home} />
              <Route path="/my-clubs" component={MyClubs} />
              <Route
                path="/account"
                component={authenticated ? Account : FourOhFour}
              />
              <Route
                path="/:clubSlug/invitation/role/:invitationId"
                component={InvitationRole}
              />
              <Route
                path="/:clubSlug/invitation/member/:memberId"
                component={InvitationMember}
              />
              <Route path="/:clubSlug" component={Club} />
            </Switch>
          </App>
        )}
      />
    </Switch>
  </Fragment>
);

export default compose(
  withRouter,
  rootUserEnhancer,
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        window.Intercom('update');
        window.gtag('config', 'UA-85428168-1', {
          page_path: nextProps.location.pathname,
        });
      }
    },
  }),
)(Router);
