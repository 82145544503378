import React, { Fragment } from 'react';
import { components } from 'react-select';
import { compose } from 'recompose';
import { withRouter, NavLink } from 'react-router-dom';
import { values, map } from 'lodash/fp';

import { rootMembershipsEnhancer } from 'modules/database/memberships';

import ocThumb from 'images/ocThumb.png';

const remap = map.convert({ cap: false })

const ClubOptionBase = ({
  data: { club: { name, logo = {} } } = {}
}) => (
  <div className="Select-value pr-4 pl-1" title={name}>
    <span className="Select-value-label d-flex align-items-center">
      <img
        src={logo.original || ocThumb}
        alt={name}
        style={{ width: '2rem', height: '2rem', marginRight: '1rem' }}
      />
      {name}
    </span>
  </div>
);

export const ClubSingleOption = ({ ...props }) => (
  <components.SingleValue {...props}>
    <ClubOptionBase {...props} />
  </components.SingleValue>
);

export const ClubOption = ({ ...props }) => (
  <components.Option {...props}>
    <ClubOptionBase {...props} />
  </components.Option>
);

const ClubSelect = ({
  loading,
  clubs,
  setUserSetting,
}) => {
  if (loading)
    return (
      <li>
        <div className="nav-list-title">Loading clubs</div>
        <div className="px-4">Please wait...</div>
      </li>
    );

  if (values(clubs).length <= 0)
    return (
      <li>
        <div className="nav-list-title">Your clubs</div>
        <div className="px-4">There's nothing here yet.</div>
      </li>
    );

  if ((clubs && Object.keys(clubs).length > 1) === false) return null;

  return (
    <Fragment>
      <li>
        <div className="nav-list-title">My Clubs</div>
      </li>
      {remap(({ logo = {}, slug, name }, selectedClubId) => (
        <li key={slug}>
          <NavLink
            exact
            to={`/${slug}`}
            className="nav-link"
            activeClassName="active"
            onClick={() => setUserSetting({ selectedClubId })}
          >
            <img
              src={logo.original || ocThumb}
              alt={name}
              style={{ width: '2rem', height: '2rem' }}
            />
            <span className="name">{name}</span>
          </NavLink>
        </li>
      ))(clubs)}
    </Fragment>
  );
};

export default compose(
  withRouter,
  rootMembershipsEnhancer,
)(ClubSelect);
