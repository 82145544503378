import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import SignIn from './SignIn'
import SignUp from './SignUp'
import Verify from './Verify'

import { signOut } from 'actions/auth'


const SignInComponent = props => {
  const { auth: { authenticated } } = props
  return authenticated ? <Redirect to="/" /> : <SignIn {...props} />
}

const SignUpComponent = props => {
  const { auth: { authenticated } } = props
  return authenticated ? <Redirect to="/" /> : <SignUp {...props} />
}

const SignOutComponent = props => {
  const { auth: { authenticated } } = props
  signOut()
  return !authenticated ? <Redirect to="/auth/sign-in" /> : null
}

const AuthRoutes = ({ auth }) => (
  <Fragment>
    <Route auth={auth} path="/auth/sign-in" render={() => <SignInComponent auth={auth} />} />
    <Route auth={auth} path="/auth/sign-up" render={() => <SignUpComponent auth={auth} />} />
    <Route auth={auth} path="/auth/sign-out" render={() => <SignOutComponent auth={auth} />} />
    <Route auth={auth} path="/auth/verify/:verificationCode" render={(routerProps) => <Verify {...routerProps} />} />
  </Fragment>
)

export default connect(({ auth }) => ({
  auth
}))(AuthRoutes)
