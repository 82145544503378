import { connect } from 'react-redux'
import {
  pick,
  isEmpty,
  filter,
  map,
  forEach,
  uniq,
  difference,
} from 'lodash/fp'
import {
  compose,
  withHandlers,
  lifecycle,
} from 'recompose'

import { onAuthChange } from 'modules/enhancers'
import { withUser } from 'modules/database/user'

const baseClubListeners = map(clubId => ({
  collection: 'clubs',
  doc: clubId,
}))


const handlers = withHandlers({
  getClubs: ({ firestore }) => (clubIds) =>
    firestore.setListeners(baseClubListeners(clubIds)),
  unsubscribeClubs: ({ firestore }) => (clubIds) =>
    firestore.unsetListeners(baseClubListeners(clubIds))
})

const createClubIds = (roles, memberships) => {
  let ids = []
  const iterate = forEach(role => ids.push(role.clubId))
  iterate(roles)
  iterate(memberships)
  return uniq(ids)
}

const createClubIdStatusSelectors = map(clubId => `clubs/${clubId}`)

const defaultConnect = connect(({ firestore }, { user, loading }) => {
  const clubIds = createClubIds(user.roles, user.memberships);
  const clubIdStatusSelectors = createClubIdStatusSelectors(clubIds)

  const loadingClubIds = pick(clubIdStatusSelectors)(firestore.status.requesting)
  const clubs = pick(clubIds)(firestore.data.clubs)

  return {
    loading: loading || !isEmpty(filter(v => v)(loadingClubIds)),
    clubs
  }
})

export const rootMembershipsEnhancer = compose(
  withUser,
  handlers,
  lifecycle({
    componentWillReceiveProps(nextProps) {
      onAuthChange(this.props, nextProps)({
        onLogout: () => {
          const { user } = this.props
          const clubIds = createClubIds(user.roles, user.memberships)

          this.props.unsubscribeClubs(clubIds)
        },
        onRoleChange: async (firstInitialise) => {
          const oldClubIds = createClubIds(this.props.user.roles, this.props.user.memberships)
          const newClubIds = createClubIds(nextProps.user.roles, nextProps.user.memberships)

          if (firstInitialise) {
            return this.props.getClubs(newClubIds)
          }

          const differentIds = difference(newClubIds)(oldClubIds)
          if (!isEmpty(differentIds)) {
            await this.props.unsubscribeClubs(differentIds)
            this.props.getClubs(differentIds)
          }
        }
      })
    }
  }),
  defaultConnect
)

export const withClubs = compose(
  withUser,
  defaultConnect
)

export default withClubs