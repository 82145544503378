import {
  SET_NAV,
} from 'actions/ui'

const initialState = {
  nav: false
}

const ACTION_HANDLERS = {
  [SET_NAV]: (state, { nav }) => ({
    ...state,
    nav
  }),
}

export default function authReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
