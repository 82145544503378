import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    Button,
    Card,
    CardBody,
    Alert,
} from 'reactstrap'

import {
  FaFacebook as IconFacebook,
  FaGoogle as IconGoogle
} from 'react-icons/fa'

import AuthView from 'components/AuthView'
import Logo from 'components/Logo'

import { signInWithSocialProvider, clearError } from 'actions/auth'
import errors from 'modules/errors'

const Container = ({ title, children, showSocialSignIn, dispatch, error }) => (
  <AuthView>
    {error && (
      <Alert isOpen color="danger" toggle={() => dispatch(clearError())} role="alert">
        <strong>Uh oh!</strong> {errors[error.code] || error.message}
      </Alert>
    )}
    <Card className="mb-3 form-card">
      <CardBody>
        <header className="mb-5">
          <Link to="/">
            <Logo />
          </Link>
          <p className="lead text-center">{title}</p>
      </header>
      {children}
      </CardBody>
    </Card>
    {showSocialSignIn && (
      <Fragment>
        <ul className="list-unstyled social-auth">
          <li className="google">
            <Button size="sm" color="link" onClick={() => dispatch(signInWithSocialProvider('google'))} aria-label="Sign in with Google">
              <IconGoogle size={20}/> 
              <span>Sign in using Google</span>
            </Button>
          </li>
          <li className="facebook">
            <Button size="sm" color="link" onClick={() => dispatch(signInWithSocialProvider('facebook'))} aria-label="Sign in with Facebook">
              <IconFacebook size={20}/> 
              <span>Sign in using Facebook</span>
            </Button>
          </li>
        </ul>
      </Fragment>
    )}
  </AuthView>
)
export default connect(({ auth }) => ({
  error: auth.error,
}))(Container)
