import React from 'react'
import ReactSelect from 'react-select'

const Select = ({ input, ...rest }) => (
  <ReactSelect
    {...rest}
    value={input.value}
    onChange={input.onChange}
    onFocus={input.onFocus}
  />
)
export default Select