import React from 'react'
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { compose, lifecycle, withState } from 'recompose'

import {
    MdNotificationsNone as IconNotification
} from 'react-icons/md'

const enhancers = compose(
    withState('online', 'setOnline', window.online),
    withState('updating', 'setUpdating', window.isUpdating)
)

const NotificationsMenu = ({ count = 0, offline, updating, setUpdating }) => (
  <div className="wrap notify hidden-sm-down">
    {(() => {
        window.setUpdating = setUpdating
        return null
    })()}
      <UncontrolledDropdown>
          <DropdownToggle tag="div">
              <IconNotification size="22" color="#fff"/>
              {count > 0 && <span className="badge badge-danger">{count}</span>}
          </DropdownToggle>
          <DropdownMenu right style={{minWidth: '18rem'}}>
            {offline && (
                <DropdownItem className="mb-2">
                    <p>You are offline. Any tasks performed will be queued up and submitted when you are reconnected.</p>
                </DropdownItem>
            )}
            <DropdownItem header>You have no new notifications</DropdownItem>
              {/* <DropdownItem divider/>
              <DropdownItem className="mb-2">
                  <p>OpenClub is updating...</p>
                  <Progress value={70} color="danger" style={{height: '3px'}}/>
              </DropdownItem>
            <DropdownItem className="d-flex align-items-center">
                <div className="ml-3">
                    <div>Andrew don't try</div>
                    <small className="text-muted">2 hours ago</small>
                </div>
            </DropdownItem>
              <DropdownItem className="d-flex align-items-center">
                  <IconDownload size="28" className="text-success"/>
                  <div className="ml-3">
                      <div>5 App Downloaded</div>
                      <small className="text-muted">5 min ago</small>
                  </div>
              </DropdownItem>
              <DropdownItem className="d-flex align-items-center">
                  <IconMail size="28" className="text-warning"/>
                  <div className="ml-3">
                      <div>Roni sent you a message</div>
                      <small className="text-muted">2 hours ago</small>
                  </div>
              </DropdownItem>
              <DropdownItem className="d-flex align-items-center">
                  <IconCake size="28" className="text-primary"/>
                  <div className="ml-3">
                      <div>You're earned a badge</div>
                      <small className="text-muted">12 hours ago</small>
                  </div>
              </DropdownItem> */}
          </DropdownMenu>
      </UncontrolledDropdown>
  </div>
)

export default enhancers(NotificationsMenu)