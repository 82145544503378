import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import { handleError } from './errorReporting';

export default (submit, validator, after) => async (
  fields,
  dispatch,
  props,
  orAfter,
) => {
  try {
    const fieldsToSubmit = (() => {
      if (!props) return fields;

      return Object.keys(props.registeredFields).filter(
        fieldPath => !!get(fields, fieldPath),
      );
    })();

    const values = {};
    fieldsToSubmit.forEach(fieldPath => {
      values[fieldPath] = get(fields, fieldPath);
    });

    // if (validator) {
    //   finalValues = validator(finalValues);
    // }

    await submit(values);

    if (after) {
      return after();
    }
    if (orAfter) {
      return orAfter();
    }
    if (!orAfter && !after) {
      toastr.success('Saved', 'The details have been saved successfully.');
    }
  } catch (e) {
    handleError(e);
    return false;
  }
};
