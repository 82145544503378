export const trackEvent = (event, metadata) => {
  if (!window.Intercom) return;
  window.Intercom('trackEvent', event, metadata);
};

export const trackClub = ({ clubId, name, size, website, spend }) => {
  if (!window.Intercom) return;

  window.Intercom('update', {
    company: {
      company_id: clubId,
      name,
      size,
      website,
      monthly_spend: spend,
    },
  });
};
