// @flow

import React, { Component } from 'react'
import { withStripe } from 'modules/stripe'
import { map, get } from 'lodash/fp'

import userEnhancer from 'modules/database/user'

import {
  FaCcMastercard,
  FaCcDinersClub,
  FaCcAmex,
  FaCcVisa,
  FaCcJcb,
  FaCreditCard,
  FaCcDiscover
} from 'react-icons/fa'

import NewCard from './NewCard'

type PropsType = {
  userSettings: Object,
}

const logos = {
  'American Express': <FaCcAmex size="24" />,
  'Diners Club': <FaCcDinersClub size="24" />,
  'MasterCard': <FaCcMastercard size="24" />,
  'Discover': <FaCcDiscover size="24" />,
  'JCB': <FaCcJcb size="24" />,
  'Visa': <FaCcVisa size="24" />,
}

const formatLast4 = (brand, last4) => {
  if (brand === 'American Express') {
    return `•••• •••••• •${last4}`
  }
  if (brand === 'Diners') {
    return `•••• •••••• ${last4}`
  }
  return `•••• •••• •••• ${last4}`
}

class Wallet extends Component<PropsType> {
  render() {
    const { props: { userSettings } } = this
    const cards = get('payments.sources.data')(userSettings)
    return (
      <div className="wallet-container">
        {!cards ? (
          <strong>You have no cards in your wallet.</strong>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th width="24px" />
                <th>Last 4 Digits</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {map(card => (
                <tr key={card.id}>
                  <td>{logos[card.brand] || <FaCreditCard size="24" />}</td>
                  <td>{formatLast4(card.brand, card.last4)}</td>
                  <td>{card.exp_month} / {card.exp_year}</td>
                </tr>
              ))(cards)}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

export {
  NewCard
}

export default withStripe(userEnhancer(Wallet))