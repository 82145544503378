export const SET_NAV = '@@ui/SET_NAV'

export const toggleNav = () => (dispatch, getState) => {
  dispatch({
    type: SET_NAV,
    nav: getState().ui.nav === false
  })
}

export const hideNav = () => (dispatch) => {
  dispatch({
    type: SET_NAV,
    nav: false
  })
}