import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withProps, compose } from 'recompose';
import cx from 'classnames';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { get } from 'lodash/fp';

import {
  MdMenu as IconMenu,
  MdLockOpen as IconLogin
} from 'react-icons/md'

import { withClubs } from 'modules/database/memberships';

import ProfileMenu from './ProfileMenu';
import NotificationsMenu from 'components/NotificationsMenu';
import Logo from 'components/Logo';

// style
import './Header.scss';

const Header = ({ user, auth, currentClub = {}, location, ...props }) => (
  <header
    className={cx(
      {
        'site-head': true,
      },
      'd-flex align-items-center justify-content-between theme',
      currentClub.colourTheme || 'blue',
    )}
  >
    {!auth.authenticated && (
      <Fragment>
        <Link
          to="/"
          style={{ marginTop: '-.4rem' }}
          className="hidden-sm-down logo"
        >
          <Logo color="#ffffff" />
        </Link>
        <div className="right-elems ml-auto d-flex">
          <div className="wrap profile">
            <UncontrolledDropdown>
              <DropdownToggle tag="div" className="text-white">
                <span className="hidden-sm-down">Sign In / Sign Up </span>
                <IconLogin size="24" color="#fff" />
              </DropdownToggle>
              <DropdownMenu right style={{ minWidth: '12rem' }}>
                <DropdownItem header>Options</DropdownItem>
                <DropdownItem tag={Link} to="/auth/sign-in">
                  Sign in
                </DropdownItem>
                <DropdownItem tag={Link} to="/auth/sign-up">
                  Sign up
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Fragment>
    )}
    {auth.authenticated && (
      <Fragment>
        <div className="wrap mr-4 hidden-lg-up">
          <IconMenu
            size="24"
            color="#fff"
            onClick={props.toggleNav}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="right-elems ml-auto d-flex">
          <NotificationsMenu />
          <ProfileMenu />
        </div>
      </Fragment>
    )}
  </header>
);

export default compose(
  withRouter,
  withClubs,
  withProps(({ userSettings, clubs }) => ({
    currentClub: get(userSettings.selectedClubId)(clubs),
  })),
)(Header);
