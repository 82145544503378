// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, Field, Form } from 'redux-form'
import { Input } from 'components/Fields'
import { required, email } from 'utils/fieldValidators'
import {
    Label,
    FormGroup,
    Button,
    FormText,
} from 'reactstrap'

import { signIn } from 'actions/auth'

import Container from './Container'

import { signInWithSocialProvider } from 'actions/auth'

const SignIn = ({ handleSubmit, submitting, dispatch, signIn }) => (
  <Container title="Sign in to OpenClub" showSocialSignIn>
    <Form onSubmit={handleSubmit} disabled={submitting}>
      <FormGroup className="mb-4">
        <Label>Email Address</Label>
        <Field
          component={Input}
          name="email"
          placeholder="eg. sam@openclub.com.au"
          required
          type="email"
          validate={[required, email]}
        />
      </FormGroup>
      {(signIn.email || '').includes('@openclub.com.au') === false ? (
        <>
          <FormGroup className="mb-4">
            <Label>Password</Label>
            <Field
              component={Input}
              name="password"
              placeholder="verysecretpassword"
              required
              type="password"
              validate={[required]}
            />
            <FormText className="text-right"><Link to="/auth/recover">Forget your password?</Link></FormText>
          </FormGroup>
          <FormGroup className="text-right text-center"> 
            <Button size="lg" color="primary" type="submit" disabled={submitting}>{submitting ? 'Processing...' : 'Sign In'}</Button>
          </FormGroup>
        </>
    ) : (
      <FormGroup className="text-right text-center"> 
        <Button
          size="lg"
          color="primary"
          onClick={() => dispatch(signInWithSocialProvider('google'))} aria-label="Sign in with Google"
          >
            Sign in with SSO
          </Button>
    </FormGroup>
  )}
      <hr />
      <div className="text-center">
        <Link to="/auth/sign-up">Don't have an account? Sign up.</Link>
      </div>
    </Form>
  </Container>
)

const SignInReduxForm = reduxForm({
  form: 'signIn',
  onSubmit: signIn
})(SignIn)

export default connect(({ auth, form: { signIn = {} } }) => ({
  error: auth.error,
  signIn: signIn.values || {}
}))(SignInReduxForm)
