import { withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import findKey from 'lodash/fp/findKey';
import pick from 'lodash/fp/pick';
import includes from 'lodash/fp/includes';
import flow from 'lodash/fp/flow';
import { compose, withHandlers, lifecycle } from 'recompose';

import { trackEvent, trackClub } from '../track';

import formHandler from 'utils/formHandler';

// Club Data Listeners

const baseListener = slug => ({
  collection: 'clubs',
  where: ['slug', '==', slug],
});

const clubSettingsListener = clubId => ({
  collection: 'clubSettings',
  doc: clubId,
});

const clubAnnouncementsListener = (clubId, options = {}) => ({
  collection: 'clubs',
  doc: clubId,
  subcollections: [
    {
      collection: 'announcements',
      orderBy: [['date', 'desc']],
      storeAs: 'announcements',
      ...options,
    },
  ],
});

const membersListener = (clubId, options = {}) => ({
  collection: 'clubs',
  doc: clubId,
  subcollections: [
    {
      collection: 'members',
      ...options,
    },
  ],
});

const memberListener = (clubId, memberId, options = {}) => ({
  collection: 'clubs',
  doc: clubId,
  subcollections: [
    { collection: 'members', doc: memberId, as: 'currentMember' },
  ],
});

const transactionsListener = clubId => ({
  collection: 'clubs',
  doc: clubId,
  subcollections: [{ collection: 'transactions' }],
});

const rolesListener = clubId => ({
  collection: 'clubs',
  doc: clubId,
  subcollections: [{ collection: 'roles' }],
});

const contactsListener = clubId => ({
  collection: 'clubs',
  doc: clubId,
  subcollections: [{ collection: 'contacts' }],
});

const reportingListener = (
  clubId,
  options = {
    orderBy: ['metricStart'],
  },
) => ({
  collection: 'clubSettings',
  doc: clubId,
  subcollections: [
    {
      collection: 'metrics',
      ...options,
    },
  ],
});

export const withClubs = compose(
  withFirestore,
  connect(({ firestore }) => ({
    clubs: firestore.data.clubs || {},
  })),
);

const handlers = withHandlers({
  getClub: ({ firestore }) => (currentSlug, nextSlug) => {
    if (currentSlug) {
      firestore.unsetListener(baseListener(currentSlug));
    }
    if (nextSlug) {
      firestore.setListener(baseListener(nextSlug));
    }
  },
  setClub: ({ firestore, clubId }) =>
    formHandler(values => {
      trackClub({
        clubId,
        name: values.name,
        website: get('about.website', values),
      });
      firestore.update(`clubs/${clubId}`, values);
    }),
  setClubSettings: ({ firestore, clubId }) =>
    formHandler(values =>
      firestore.set(`clubs/${clubId}`, values, { merge: true }),
    ),
  rolesData: ({ firestore, clubId, club }) => method => {
    if (method === 'subscribe') {
      firestore.setListener(rolesListener(clubId));
    }
    if (method === 'unsubscribe') {
      firestore.unsetListener(rolesListener(clubId));
    }
  },
  clubAnnouncements: ({ firestore, clubId }) => (method, options) => {
    return (method === 'subscribe'
      ? firestore.setListener
      : firestore.unsetListener)(clubAnnouncementsListener(clubId, options));
  },
  clubSettings: ({ firestore, clubId }) => method => {
    return (method === 'subscribe'
      ? firestore.setListener
      : firestore.unsetListener)(clubSettingsListener(clubId));
  },
  clubMetrics: ({ firestore, clubId }) => method => {
    return (method === 'subscribe'
      ? firestore.setListener
      : firestore.unsetListener)(reportingListener(clubId));
  },
  clubTransactions: ({ firestore, clubId }) => (method, options) => {
    return (method === 'subscribe'
      ? firestore.setListener
      : firestore.unsetListener)(transactionsListener(clubId, options));
  },
  clubMembers: ({ firestore, clubId, match }) => async (method, options) => {
    if (method === 'unsubscribe') {
      return firestore.unsetListener(membersListener(clubId, options));
    }
    return firestore.setListener(membersListener(clubId, options));
  },
  clubContacts: ({ firestore, clubId }) => async (method, options) => {
    if (method === 'unsubscribe') {
      return firestore.unsetListener(contactsListener(clubId, options));
    }
    return firestore.setListener(contactsListener(clubId, options));
  },
  getMember: ({
    firestore,
    clubId,
    match: {
      params: { memberId },
    },
  }) => (method, options) => {
    return (method === 'subscribe'
      ? firestore.setListener
      : firestore.unsetListener)(memberListener(clubId, memberId, options));
  },
  createMember: ({ firestore, clubId }) => data => {
    trackEvent('create-member');
    return firestore.add(`clubs/${clubId}/members`, data);
  },
  updateMember: ({ firestore, clubId }) => (memberId, data) => {
    trackEvent('update-member');
    return firestore.update(`clubs/${clubId}/members/${memberId}`, data);
  },
  deleteMember: ({ firestore, clubId }) => memberId => {
    trackEvent('delete-member', { clubId, memberId });
    return firestore.delete(`clubs/${clubId}/members/${memberId}`);
  },
  bulkWriteMembers: ({ firestore, clubId }) => data => {
    trackEvent('bulk-member-import');
    return Promise.all(
      data.map(doc => firestore.add(`clubs/${clubId}/members`, doc)),
    );
  },
});

const defaultConnector = connect(({ firestore }, { match }) => {
  if (!match) {
    return {};
  }

  const {
    params: { clubSlug, memberId },
  } = match;

  const clubId = findKey({ slug: clubSlug }, firestore.data.clubs);

  const loading = flow(
    pick([
      `clubs/${clubId}`,
      `clubs?where=slug:==:${clubSlug}`,
      `clubs/${clubId}/members/${memberId}`,
    ]),
    includes(true),
  )(firestore.status.requesting);

  const settingsLoading = flow(
    pick([`clubSettings/${clubId}`]),
    includes(true),
  )(firestore.status.requesting);

  // Collections
  const club = get(`data.clubs.${clubId}`, firestore);
  const settings = get(`data.clubSettings.${clubId}`, firestore);
  const member = get(`data.clubs.${clubId}.members.${memberId}`, firestore);

  const notfound = !club;

  return {
    loading,
    club,
    clubId,
    notfound,
    settings,
    member,
    settingsLoading,
  };
});

export const rootClubEnhancer = compose(
  withRouter,
  withFirestore,
  handlers,
  defaultConnector,
  lifecycle({
    componentWillMount() {
      const { club = {}, match: { params: { clubSlug } } = {} } = this.props;
      if (clubSlug && club.slug !== clubSlug) {
        this.props.getClub(null, clubSlug);
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        match: {
          params: { clubSlug: currentSlug },
        },
      } = this.props;
      const {
        match: {
          params: { clubSlug: nextSlug },
        },
      } = nextProps;

      if (currentSlug !== nextSlug) {
        this.props.getClub(currentSlug, nextSlug);
      }
    },
  }),
);

export const withClub = compose(
  withRouter,
  withFirestore,
  defaultConnector,
  handlers,
);

export default withClub;
