import { toastr } from 'react-redux-toastr';
import * as Sentry from '@sentry/browser';

const commonErrors = {
  noNetwork: {
    title: 'Connection Lost',
    message: 'OpenClub cannot connect to the internet. Please try again.',
  },
};

const errors = {
  'auth/invalid-user-token': {
    title: 'Session Expired',
    message: 'Your session has expired. Please sign in again.',
    func: () => {
      localStorage.clear();
      window.location = window.location.origin + '/auth/sign-in';
    },
  },
  'auth/network-request-failed': commonErrors.noNetwork,
  'auth/user-disabled': {
    title: 'Account Suspended',
    message:
      'Your account has been suspended. Please contact support@openclub.com.au for an explanation.',
    func: () => localStorage.clear(),
  },
  unauthenticated: {
    title: 'Unauthorised',
    message: 'You must be logged in to use this feature.',
  },
  unavailable: {
    title: 'Service Unavailable',
    message: `We're experiencing an outage and cannot complete your request. Please try again shortly.`,
  },
  'not-found': {
    title: 'Resource Not Found',
    message: `The resource you've requested cannot be found. Please go back and try again or contact support@openclub.com.au.`,
  },
  'permission-denied': {
    title: 'Access Denied',
    message:
      'You do not have permission to modify this resource. Perhaps your role or membership have been revoked. Please contact support@openclub.com.au if you think this is in error.',
  },
};

Sentry.init({
  dsn: process.env.OC__RAVEN,
  environment:
    process.env.NODE_ENV === 'production' &&
    ['app.openclub.com.au', 'app.openclub.uk'].includes(
      window.location.hostname,
    )
      ? 'production'
      : 'development',
});

export const handleError = error => {
  const handler = errors[error.code || ''];
  if (handler) {
    handler.func();
    toastr.error(handler.title, handler.message);
    return;
  }
  toastr.error(
    'Unknown Error',
    'An error has occurred while processing your request. This error has been automatically reported to our technical support team. Please try again later.',
  );
  if (process.env.NODE_ENV === 'development') console.error(error);
  Sentry.captureException(error);
};

export default Sentry;
