// @flow
import { type Dispatch } from 'redux'
import { toastr } from 'react-redux-toastr'
import errorReporting from 'utils/errorReporting'

import { auth, providers } from 'modules/firebase'

export const STATE_CHANGED = '@@auth/STATE_CHANGED'
export const AUTH_ERROR = '@@auth/AUTH_ERROR'

type SignInFormValues = {
  email: string,
  password: string,
}

type SignUpFormValues = {
  email: string,
  password: string,
  confirmPassword: string,
}

const handleError = dispatch => error => dispatch({
  type: AUTH_ERROR,
  code: error.code,
  message: error.message
})

export const signUp = async ({ email, password, confirmPassword }: SignUpFormValues, dispatch: Dispatch) => {
  await auth.createUserWithEmailAndPassword(email, password)
  .catch(error => handleError(dispatch)(error))
}

export const signIn = async ({ email, password }: SignInFormValues, dispatch: Dispatch) => {
  await auth.signInWithEmailAndPassword(email, password)
    .catch(error => handleError(dispatch)(error))
}

export const signInWithSocialProvider = (provider: 'google' | 'facebook') => (dispatch: Dispatch) => {
  auth.signInWithRedirect(providers[provider])
}

export const listenToAuthStateChanges = () => (dispatch: Dispatch) => {
  auth.onAuthStateChanged(user => {
    if (user) {
      errorReporting.configureScope(scope => {
        scope.setUser({ id: user.uid })
      })
    }
    dispatch({
      type: STATE_CHANGED,
      user,
    })
    document.getElementById("loading-overlay").style.display = 'none'; //eslint-disable-line
  })
}

export const signOut = () => {
  auth.signOut()
  .then(() => toastr.success('Signed out', 'You have been signed out successfully.')
)
}

export const clearError = () => (dispatch: Dispatch) => dispatch({ type: AUTH_ERROR })
