import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'

import createStore from './store/create_store'
import Router from './Router'
import 'modules/stripe'
import 'utils/errorReporting'

// import main style dependency file
import 'typeface-open-sans';
import 'cropperjs/dist/cropper.css';
import 'react-redux-toastr/src/styles/index.scss';
import './index.scss';

const store = createStore();

window.Intercom("boot", {
  app_id: "i9v7w85z"
})

ReactDOM.render((
  <Provider store={store}>
      <Fragment>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <ReduxToastr
          timeout={5000}
          newestOnTop
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
      </Fragment>
  </Provider>
), document.getElementById('root'));
