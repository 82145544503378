import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import MainNav from 'components/MainNav';
import Header from 'components/Header';
import UserOnboarding from 'components/UserOnboarding';
import Footer from 'components/Footer';
import userEnhancer from 'modules/database/user';
import { toggleNav, hideNav } from 'actions/ui';

let previousLocationKey = null;

const enhancers = compose(
  withRouter,
  userEnhancer,
  connect(({ ui }) => ({
    nav: ui.nav,
  })),
  withProps(({ location, dispatch }) => {
    if (location.key !== previousLocationKey) {
      setTimeout(() => dispatch(hideNav()), 15);
      previousLocationKey = location.key;
    }
  }),
);

const App = props => {
  const { auth, user, loading, children, nav, dispatch } = props;

  return !auth.authenticated ? (
    <div className="view-public">
      {/* {<Alert color="danger" className="content-container m-0">
        <strong>Maintenance Notice: </strong> We're performing maintenance until 11:00pm AEST +10 — users may experience errors during this time.
      </Alert>} */}
      <Header toggleNav={() => dispatch(toggleNav())} {...props} />
      <div className="public-content-wrapper">
        {children}
        <Footer />
      </div>
    </div>
  ) : (
    <div className="app-wrapper">
      {/* {<Alert color="danger" className={`content-container m-0 ${navMini ? 'full' : ''}`}>
        <strong>Maintenance Notice: </strong> We're performing maintenance until 11:00pm AEST +10 — users may experience errors during this time.
      </Alert>} */}
      {!loading && !user.onboardingComplete && <UserOnboarding />}
      <MainNav mini={nav} toggleNav={() => dispatch(toggleNav())} {...props} />
      <div className={`content-container ${nav ? 'full' : ''}`}>
        <div
          className="menu-dropshadow"
          style={{ display: nav ? 'block' : 'none' }}
          onClick={() => dispatch(hideNav())}
        />
        <Header toggleNav={() => dispatch(toggleNav())} {...props} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default enhancers(App);
