import { connect } from 'react-redux'
import { isEqual } from 'lodash/fp'


export const withAuth = connect(state => ({
  auth: state.auth
}))

export const onAuthChange = (thisProps, nextProps) => 
 ({ onLogout, onLogin, onRoleChange }) => {
  const { auth: oldAuth, loading: oldLoading, user: { roles: oldRoles, memberships: oldMemberships } = {} } = thisProps
  const { auth: newAuth, loading: newLoading, user: { roles: newRoles, memberships: newMemberships } = {} } = nextProps
  if (oldAuth.authenticated !== newAuth.authenticated) {
    if (newAuth.authenticated === true) {
      onLogin && onLogin(newAuth.user)
    }
    if (newAuth.authenticated === false && oldAuth.authenticated === true) {
      onLogout && onLogout(oldAuth.user)
    }
  }

  if (!isEqual(oldRoles)(newRoles) || !isEqual(oldMemberships)(newMemberships)) {
    if (oldLoading === true && newLoading === false) {
      onRoleChange && onRoleChange(true)
    } else {
      onRoleChange && onRoleChange()
    }
  }
}