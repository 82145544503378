import React, { Fragment, Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { NavLink as BSNavLink, Collapse } from 'reactstrap';
import { compose } from 'recompose';
import { findKey } from 'lodash/fp';
import cx from 'classnames';

import {
  FaRegNewspaper, // Latest
  FaRegIdBadge, // Membership
  FaAddressBook, // Contacts
  FaIdCardAlt, // My Membership
  FaChartPie, // Dashboard
  FaUsers, // Memberships
  FaFileInvoiceDollar, // Finances
  FaCog, // Settings
  FaCaretDown,
} from 'react-icons/fa';

import { withClub, withClubs } from 'modules/database/club';

import HasRoles from 'components/HasRoles';

const NavLink = props => <BSNavLink {...props} tag={Link} />;

class ClubMenu extends Component {
  originalState = {
    communications: false,
    memberships: false,
    finances: false,
    settings: false,
  };

  state = this.originalState;

  toggleMenu = key => e => {
    e.preventDefault();
    if (this.state[key] === true) return this.setState(this.originalState);
    this.setState({ ...this.originalState, [key]: true });
  };

  render() {
    const {
      props: { club, clubs, match },
    } = this;

    if (!match) return null;

    const { params: { clubSlug } = {} } = match;

    // Try and find the selectedClubId in the list
    const selectedClubId = findKey({ slug: clubSlug }, clubs);

    // Provide a default "empty" club based on the current club in view
    const currentClub = clubs[selectedClubId];

    // If there's no current club, we can't display the menu anyway.
    if (!currentClub) return null;

    return (
      <Fragment>
        <li>
          <div className="nav-list-title">{currentClub.name}</div>
        </li>
        <li>
          <NavLink exact to={`/${currentClub.slug}`} activeClassName="active">
            <FaRegNewspaper size="20" />
            <span className="name">Latest</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/${currentClub.slug}/membership`}
            activeClassName="active"
          >
            <FaRegIdBadge size="20" />
            <span className="name">Membership</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/${currentClub.slug}/contacts`}
            activeClassName="active"
          >
            <FaAddressBook size="20" />
            <span className="name">Contacts</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/${currentClub.slug}/my-membership`}
            activeClassName="active"
          >
            <FaIdCardAlt size="20" />
            <span className="name">My Membership</span>
          </NavLink>
        </li>
        <HasRoles
          replaceClubId={selectedClubId}
          roles={['admin', 'finances', 'reporting', 'events', 'members']}
        >
          <li>
            <div className="nav-list-title">Administration</div>
          </li>
        </HasRoles>
        <HasRoles replaceClubId={selectedClubId} roles={['admin', 'reporting']}>
          <li>
            <NavLink
              to={`/${currentClub.slug}/overview`}
              activeClassName="active"
            >
              <FaChartPie size="20" />
              <span className="name">Insights</span>
            </NavLink>
          </li>
        </HasRoles>
        <HasRoles replaceClubId={selectedClubId} roles={['admin', 'members']}>
          <li className={cx({ selected: this.state.memberships })}>
            <a href="#memberships" onClick={this.toggleMenu('memberships')}>
              <FaUsers size="20" />
              <span className="name">Memberships</span>
              <FaCaretDown size="14" className="icon-down" />
            </a>
            <Collapse isOpen={this.state.memberships}>
              <ul className="inner-drop list-unstyled">
                <li>
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={Link}
                    to={`/${currentClub.slug}/memberships`}
                  >
                    Members
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    tag={Link}
                    to={`/${currentClub.slug}/memberships/plans`}
                  >
                    Plans
                  </NavLink>
                </li>
                <HasRoles replaceClubId={selectedClubId} roles={['admin']}>
                  <li>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to={`/${currentClub.slug}/memberships/bulk-import`}
                    >
                      Bulk Import
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to={`/${currentClub.slug}/memberships/custom-fields`}
                    >
                      Custom Fields
                    </NavLink>
                  </li>
                </HasRoles>
              </ul>
            </Collapse>
          </li>
        </HasRoles>
        <HasRoles replaceClubId={selectedClubId} roles={['admin', 'finances']}>
          <li className={cx({ selected: this.state.finances })}>
            <a href="#finances" onClick={this.toggleMenu('finances')}>
              <FaFileInvoiceDollar size="20" />
              <span className="name">Finances</span>
              <FaCaretDown size="14" className="icon-down" />
            </a>
            <Collapse isOpen={this.state.finances}>
              <ul className="inner-drop list-unstyled">
                <li>
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={Link}
                    to={`/${currentClub.slug}/finances`}
                  >
                    Payments
                  </NavLink>
                </li>
                <HasRoles replaceClubId={selectedClubId} roles={['admin']}>
                  <li>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to={`/${currentClub.slug}/finances/payments`}
                    >
                      Setup Payments
                    </NavLink>
                  </li>
                </HasRoles>
              </ul>
            </Collapse>
          </li>
        </HasRoles>
        <HasRoles replaceClubId={selectedClubId} roles={['admin']}>
          <li className={cx({ selected: this.state.settings })}>
            <a href="#settings" onClick={this.toggleMenu('settings')}>
              <FaCog size="20" />
              <span className="name">Settings</span>
              <FaCaretDown size="14" className="icon-down" />
            </a>
            <Collapse isOpen={this.state.settings}>
              <ul className="inner-drop list-unstyled">
                <li>
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={Link}
                    to={`/${currentClub.slug}/settings/details`}
                  >
                    Details
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    tag={Link}
                    to={`/${currentClub.slug}/settings/branding`}
                  >
                    Branding
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    tag={Link}
                    to={`/${currentClub.slug}/settings/email`}
                  >
                    Email
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    tag={Link}
                    to={`/${currentClub.slug}/settings/roles`}
                  >
                    Roles
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
        </HasRoles>
      </Fragment>
    );
  }
}

export default compose(
  withClubs,
  withClub,
)(ClubMenu);
